<template>
  <v-fade-transition leave-absolute>
    <v-overlay :value="updating" v-if="updating" absolute>
      <v-progress-circular indeterminate size="64" width="6"></v-progress-circular>
    </v-overlay>

    <div class="client-features" v-else>
      <span class="tw-text-xl tw-font-semibold tw-ml-6">{{ $t('clients.features.title') }}</span>
      <v-breadcrumbs :items="breadcrumbs" />
      <v-list class="tw-my-8 tw-mx-0 sm:tw-mx-6 tw-py-6 tw-px-0 sm:tw-px-2">
        <v-list-item-content>
          <clients-features-recursive ref="features" :features="features" :client="currentClient" />
        </v-list-item-content>
      </v-list>
      <v-btn @click="save" class="tw-mx-auto" color="#449afd" dark>
        {{ $t('button.save') }}
      </v-btn>
    </div>
  </v-fade-transition>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { union } from 'lodash'

import ClientsFeaturesRecursive from '@/components/Clients/Features/Recursive.vue'

export default {
  name: 'ClientsFeatures',
  components: {
    ClientsFeaturesRecursive,
  },
  data() {
    return {
      activeFeatureId: {},
      modelFeatures: [],
    }
  },
  async created() {
    this.setUpdating(true)
    await Promise.all([this.getClientCore({ uuid: this.$route.params.id }), this.getFeatures()])
    this.setUpdating(false)
  },
  computed: {
    ...mapState({
      currentClient: state => state.client.currentClient,
      features: state => state.setting.features,
      updating: state => state.backoffice.updating,
    }),
    breadcrumbs() {
      return [
        {
          text: this.$t('clients.features.breadcrumbs.client'),
          to: { name: 'ClientsList' },
          exact: true,
        },
        {
          text: this.currentClient.name,
          to: { name: 'ClientsDashboard', params: { id: this.$route.params.id } },
          exact: true,
        },
        {
          text: this.$t('clients.features.breadcrumbs.features'),
          to: { name: 'ClientFeatures', params: { id: this.$route.params.id } },
          exact: true,
        },
      ]
    },
  },
  methods: {
    ...mapActions({
      patchClient: 'client/patchClient',
      getClientCore: 'client/getClientCore',

      setUpdating: 'backoffice/setUpdating',
      setAlert: 'backoffice/setAlert',

      getFeatures: 'setting/getFeatures',
    }),
    async save() {
      try {
        const mergeArray = union.apply(false, Object.values(this.$refs.features.activeFeatureId))
        await this.patchClient({ uuid: this.currentClient.uuid, client: { features: mergeArray } })
        this.setAlert({
          color: 'success',
          text: this.$t('notification.update.client', { name: this.currentClient.name }),
        })
      } catch {
        this.setAlert({
          color: 'error',
          text: this.$t('notification.error.default'),
        })
      }
    },
  },
}
</script>

<style lang="scss">
.client-features__treeview {
  &--disabled {
    .v-treeview-node__level {
      display: none !important;
    }
    .v-treeview-node__checkbox {
      display: none !important;
    }
  }

  .v-treeview-node__root {
    position: relative;
  }
}
</style>

<style lang="scss" scoped>
.client-features {
  @apply tw-h-full tw-mx-0 tw-m-8 sm:tw-m-8;
}
</style>
